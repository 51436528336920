.btn,
.btn.btn-warning,
input[type=submit],
.button:not(.cta),
.button.secondary {
    border: none;
    border-bottom: 6px solid var(--btn_border) !important;
}

input[type=submit]:hover,
.btn:hover,
.btn.btn-warning:hover,
input[type=submit]:hover,
.button:not(.cta):hover,
.button.secondary:hover {
    border: none;
    border-bottom: 6px solid var(--btn_border) !important;
}

.button.secondary {
    color: var(--btn_font);
    background: var(--btn);
}

.button.secondary:is(:active, :hover) {
    border-color: var(--btn_hover);
    background-color: var(--btn_hover);
    color: var(--btn_font_hover);
}

.intro-section .button.secondary {
    border: none;
    color: var(--slider_btn_font);
    background: var(--slider_btn);
}

.intro-section .button.secondary:is(:active, :hover) {
    border-color: var(--slider_btn_hover);
    background-color: var(--slider_btn_hover);
    color: var(--slider_btn_font_hover);
}
